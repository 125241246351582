<template>
    <div class="wrapper-div ">
      <TopBar  />
    
        <div  class="d-flex  ">
          <SideNav ActiveTab ="ntumaAccounts"/>
          <div  class="container-fluid px-middle top-div">
             
              <div  class="card data-card shadow-sm ">
                  <div class="card-body def-card-h pt-5 px-5">
                      <div class="">
                          <div class=" ">
                              <div class="d-flex px-table-top mb-2">
                                  <div class="w-5-of-6 d-flex">
                                      <p  > <b>Ntuma Accounts</b> </p>
                                  </div>
                                 
                                   <div class="d-flex justify-content-end w-1-of-6  pb-3">
                                          <!-- <img height="20" class="mr-3"  src="../../assets/download.svg" alt="logo" > -->
                                  </div>
                              </div>
                              <table style="box-shadow: none;" class="table table-borderless  table-hover">
                                  <thead class="">
                                        <tr>
                                            <th>Name</th>
                                            <th>Primary Staff</th>
                                            <th>Account Approved</th>
                                            <th>Wallet</th>
                                            <th>Created</th>
                                            <th></th>
                                        </tr>
                                      
                                  </thead>
                                  <tbody>
                                      <tr v-for="(ntumaAccount,key) in ntumaAccounts" :key="key">
                                         
                                          <td>
                                              <p class="mt-link"> {{ntumaAccount.account_name}}</p>
                                          </td>
                                          <td>
                                              <p class="mt-link"> {{ntumaAccount.user.email}}</p>
                                          </td>
                                          <td>
                                            <p class="mt-link ">
                                                <span v-if="!ntumaAccount.is_approved" class="badge bagde-3-danger text-13 text-gray-800">No</span>
                                                <span v-else class="badge bagde-3-success text-13 text-gray-800">Yes</span>
                                            </p>
                                          </td>
                                          <td>
                                              <p class="mt-link">UGX {{(ntumaAccount.userWallet)? (ntumaAccount.userWallet.current_balance?ntumaAccount.userWallet.current_balance:"0.0") :"0.0"}}</p>
                                          </td>
                                          <td>
                                              <p class="mt-link"> {{formatDate2(ntumaAccount.created_at)}}</p>
                                          </td>
                                          <td style="min-width:80px" class=""> 
                                              <div class="hover-btn d-flex justify-content-end mr-5">
                                                <div class="dropdown">
                                                    <button type="button" class="btn btn-secondary  text-gray-600 btn-sm rounded-4 py-1 mr-3 " data-bs-toggle="dropdown">
                                                        action
                                                    </button>
                                                    <ul class="dropdown-menu">
                                                        <li @click.prevent="ntumaAccountDetails(ntumaAccount)" ><a class="dropdown-item" href="#">Details</a></li>
                                                        <li><a class="dropdown-item" href="#">Staff</a></li>
                                                        <li><a class="dropdown-item" href="#">Transactions</a></li>
                                                    </ul>
                                                </div>
                                                
                                              </div>
                                          </td>
                                      </tr>
                                  
                                  </tbody>
                              </table>
                              
                              <p v-if="noRecordsYet" class="text-center mt-6 text-13">No Records</p>
  
                              <div  class="justify-content-end mt-5 d-flex">
                                  <div>
                                      <button :disabled="currentPage==1" @click="getPreviousPage" class="btn btn-outline-secondary btn-sm">Previous</button>
                                      <button :disabled="currentPage==totalPages" @click="getNextPage" class="btn btn-outline-secondary btn-sm ml-3">Next</button>
                                  </div>
                              </div>
                             
                              <DataLoader ref="dataLoader"/>
                          
                          </div>
                      </div>
                  </div>
  
              </div>
              
                <div id="accountDetails" class="modal">
                    <div class="modal-dialog modal-xl" role="document">
                        <div class="modal-content">
                        
                        <div class="modal-body px-5 py-4">
                            <ul class="nav nav-tabs" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link active" data-bs-toggle="tab" href="#details" aria-selected="true" role="tab">Business Details</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link" data-bs-toggle="tab" href="#profile" aria-selected="false" tabindex="-1" role="tab">Staff</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link" data-bs-toggle="tab" href="#profile" aria-selected="false" tabindex="-1" role="tab">Collections</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link" data-bs-toggle="tab" href="#profile" aria-selected="false" tabindex="-1" role="tab">Settlements</a>
                                </li>
                                
                            </ul>
                            <div id="myTabContent" class="tab-content">
                                <div class="tab-pane fade show active" id="details" role="tabpanel">
                                    <div v-if="selectedNtumaAccount" class="row">
                                        <div  class="col-md-8   px-3 py-4">
                                            <div class="d-flex w-full mb-3"><span class="w-1-of-3  text-gray-600" >Approved:</span> 
                                                <div class="form-check form-switch">
                                                    <input @change="approvalStatusChanged" v-model="appproval_status"  class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" >
                                                    <label class="form-check-label" for="flexSwitchCheckChecked">
                                                        <span v-if="!appproval_status" class="badge bg-gray-200 text-gray-800" >No</span>
                                                        <span v-else class="badge bagde-3-success text-gray-800" >Yes</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <p class="d-flex w-full"><span class="w-1-of-3  text-gray-600" >Business Name:</span> <span>{{ selectedNtumaAccount.account_name }}</span></p>
                                            <p class="d-flex w-full"><span class="w-1-of-3  text-gray-600" >Industry:</span> <span>{{ (selectedNtumaAccount.business_details)? selectedNtumaAccount.business_details.business_industry:"n/a" }}</span></p>
                                            <p class="d-flex w-full"><span class="w-1-of-3  text-gray-600" >Business email:</span> <span>{{  (selectedNtumaAccount.business_details)? selectedNtumaAccount.business_details.business_email:"n/a" }}</span></p>
                                            <p class="d-flex w-full"><span class="w-1-of-3  text-gray-600" >Business phone:</span> <span>{{  (selectedNtumaAccount.business_details)? selectedNtumaAccount.business_details.business_phone:"n/a" }}</span></p>
                                            <p class="d-flex w-full"><span class="w-1-of-3  text-gray-600" >Registration date:</span> <span>{{  (selectedNtumaAccount.business_details)? selectedNtumaAccount.business_details.reg_date:"n/a" }}</span></p>
                                            <p class="d-flex w-full"><span class="w-1-of-3  text-gray-600" >Country:</span> <span>{{  (selectedNtumaAccount.business_details)? selectedNtumaAccount.business_details.country:"n/a" }}</span></p>
                                            <p class="d-flex w-full"><span class="w-1-of-3  text-gray-600" >City:</span> <span>{{  (selectedNtumaAccount.business_details)? selectedNtumaAccount.business_details.city:"n/a" }}</span></p>
                                            <p class="d-flex w-full"><span class="w-1-of-3  text-gray-600" >Address 1:</span> <span>{{  (selectedNtumaAccount.business_details)? selectedNtumaAccount.business_details.address_1:"n/a" }}</span></p>
                                            <p class="d-flex w-full"><span class="w-1-of-3  text-gray-600" >Address 2:</span> <span>{{  (selectedNtumaAccount.business_details)? selectedNtumaAccount.business_details.address_2:"n/a" }}</span></p>
                                            <p class="d-flex w-full"><span class="w-1-of-3  text-gray-600" >Certificate:</span> 
                                                 <a   target="_blank" class="text-green" v-if="selectedNtumaAccount.business_details"  :href='storageUrl+"/"+selectedNtumaAccount.business_details.certificate_url'><span>{{   getCertifactName(selectedNtumaAccount.business_details.certificate_url) }}</span>
                                                </a> 
                                                <span v-else>n/a</span>
                                            </p>

                                            <!-- <img style="height:200px" class=" w-2-of-3 img-thumbnail" src="../../assets/certification-placeholder.png" alt="" srcset=""> -->
                                        </div>
                                        <div  class="col-md-4 ">
                                            <!-- <div class="d-flex">
                                                <button type="button" class="btn px-2  mt-3 mb-5 mr-3 project-btn-green w-full">Approve</button>
                                                <button type="button" class="btn px-2  mt-3 mb-5 btn-danger w-full">Decline</button>
                                            </div> -->
                                            <button type="button" class="btn px-2  mt-3 mb-5 btn-danger w-full">Block</button>

                                     
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="profile" role="tabpanel">
                                   

                                </div>
                            
                            </div>

                           
                           
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary">Save changes</button>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                        </div>
                    </div>
                </div>
  
              
          
              
          </div>
          
        </div>
    </div>
  
  </template>
  
  
  <script>
  // @ is an alias to /src
  import SideNav from '../SideNav.vue'
  import TopBar from '../Elements/TopBar.vue'
  import commons from "../../commons"
  import axios from "axios";
  // import Button from "../Elements/Button.vue"
  import iziToast from "../../mixin/IziToast"
  import DataLoader from "../Elements/DataLoader.vue"
import Swal from 'sweetalert2';
  
  export default {
    name: 'WebHooks',
    mixins:[commons,axios,iziToast],
    components: {
      SideNav,TopBar,DataLoader
    },
    data() {
        return {
          noLinksYet:true,
          createNewLink:false,
          nextCursor:null,
          prevCursor:null,
          ntumaAccounts:[],
          noRecordsYet:false,
          totalPages:0,
          lastCursor:null,
          currentPage:1,
          selectedNtumaAccount:null,
          appproval_status:false
        }
    },
    mounted() {
      this.getntumaAccounts()
    },
    methods: {
        getCertifactName(companyCertificate){
            let string_array = companyCertificate.split("/")
            return string_array[string_array.length-1];
        },
        resetRecords(){
            this.nextCursor =null;
           this.totalPages=0
           this.lastCursor=null
          this.currentPage=1
          this.getntumaAccounts()
        },
     
      approvalStatusChanged(){
        let text =""
        let proceedbtn ="";
        let title ="";
        if (this.appproval_status) {
            text="Account will be allowed to perform transactions in the live enviroment";
            proceedbtn ="Yes, Approve!";
            title ="Appprove";
        }else{
             text ="Account will only be allowed to perform transactions in the test enviroment";
             proceedbtn ="Yes, Disable!";
             title ="Disable";
        }
                 // eslint-disable-next-line
        Swal.fire({
            title: title,
            text: text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: proceedbtn
            }).then((result) => {
                if (result.isConfirmed) {
                   this.proceedWithApproval()
     
                }else{
                    if (this.appproval_status) {
                        this.appproval_status =false
                    }else{
                        this.appproval_status = true
                    }
                }
            })
        console.log(this.appproval_status)
      },
      proceedWithApproval(){
         /* eslint-disable */
        $("#accountDetails").modal("hide")
        $("#modalLoader").modal("show")
        axios({
            method: 'post',
            url: `${this.baseUrl}/api/admin/change-approval`,
            headers: {
              Authorization: 'Bearer ' + this.getCookie("accessToken"),
            },
            data:{
                ntuma_account_id:this.selectedNtumaAccount.id,
                approved: this.appproval_status
            }
        }).then(()=>{
            this.showSuccessMessage("success")
             // eslint-disable-next-line
            $("#modalLoader").modal("hide")
            this.resetRecords()

        }) .catch( (error)=> {
            if (error.response) {
              this.showErrorMessage(error.response.data.message)
            }else{
              this.showErrorMessage("Something went wrong")
            }
        });
      },
      ntumaAccountDetails(ntumaAccount){
        this.selectedNtumaAccount = ntumaAccount
        this.appproval_status = this.selectedNtumaAccount.is_approved
        // eslint-disable-next-line
        $("#accountDetails").modal("show")
      },
   
     
      getNextPage(){
          this.getntumaAccounts()
           this.currentPage+=1;
      },
      getPreviousPage(){
          this.nextCursor = this.prevCursor
          this.getntumaAccounts()
          this.currentPage-=1;
      },
      getntumaAccounts(){
          this.noRecordsYet = false
          this.ntumaAccounts =[]
          this.$nextTick(() => {
              this.$refs.dataLoader.start()
          });
  
          var ntumaAccountsUrl =""
          if (this.nextCursor) {
              ntumaAccountsUrl = `${this.baseUrl}/api/admin/ntuma-accounts?cursor=${this.nextCursor}`;
          }else{
              ntumaAccountsUrl = `${this.baseUrl}/api/admin/ntuma-accounts`;
          }
           axios.get(ntumaAccountsUrl,{
                  headers: {
                      Authorization: 'Bearer ' + this.getCookie("accessToken"),
                  }
              }).then((res)=>{
                  this.ntumaAccounts =[];
                  this.ntumaAccounts.length =0;
                  this.ntumaAccounts = res.data.data.items;
                  this.nextCursor =res.data.data.nextCursor
                  this.$refs.dataLoader.stop()
                  if (res.data.data.items.length ==0) {
                      this.noRecordsYet = true
                  }
                  this.totalPages = res.data.data.totalPages
                  this.lastCursor = res.data.data.lastCursor
              }).catch((error)=>{
                  console.log(error);
              })
      }
        
    },
  }
  </script>
  