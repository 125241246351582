<template>
    <div class="wrapper-div ">
      <TopBar @enviroment-changed="enviromentChanged" @account-switched ="accountSwitched" />
    
        <div  class="d-flex  ">
          <SideNav ActiveTab ="collections"/>
          <div  class="container-fluid px-middle top-div">
             
              <div  class="card data-card shadow-sm ">
                  <div class="card-body def-card-h pt-5 px-5">
                      <div class="">
                          <div class=" ">
                              <div class="d-flex px-table-top mb-2">
                                  <div class="w-5-of-6 d-flex">
                                      <p  > <b>Collections</b> </p>
                                     
                                     
                                  </div>
                                 
                                   <div class="d-flex justify-content-end w-1-of-6  pb-3">
                                      <a @click.prevent="downloadCollections" href="#">
                                          <img height="20" class="mr-3"  src="../../assets/download.svg" alt="logo" >
                                      </a>
                                  </div>
                              </div>
                              <table style="box-shadow: none;" class="table table-borderless  table-hover">
                                  <thead class="">
                                      <tr>
                                          <th>Ntuma Account</th>
                                          <th>Customer</th>
                                          <th>Amount</th>
                                          <th>Channel</th>
                                          <th>Transaction ID</th>
                                          <th>Status</th>
                                          <th>Date</th>
                                          <th> <div class="d-flex justify-content-end"></div> </th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr v-for="(collection,key) in collections" :key="key">
                                          <td>
                                              
                                              <p class="mt-link"> {{collection.ntuma_account.account_name}}</p>
                                          </td>
                                          <td>
                                              
                                              <p class="mt-link"> {{collection.msisdn}}</p>
                                          </td>
                                          <td>
                                             <p class="mt-link">  UGX {{formatNumber(collection.amount) }}</p>
                                          </td>
                                          
                                          <td>
                                               <p class="mt-link">{{collection.collection_channel}}</p>
                                          </td>
                                          <td>
                                               <p class="mt-link">{{collection.transaction_id}}</p>
                                          </td>
                                          <td> 
                                              <p class="mt-link ">
                                               <span class="badge bg-gray-200 text-13 text-gray-800 text-normal" v-if="collection.status=='pending'">Pending</span>
                                                  <span class="badge bagde-3-danger text-13 text-gray-800" v-if="collection.status=='failed'">Failed</span>
                                              <span class="badge bagde-3-success text-13 text-gray-800" v-if="collection.status=='successful'">Successful</span>
                                              </p>
                                              
                                          </td>
                                          <td> 
                                              <p class="mt-link">{{ formatDate2(collection.created_at)}}</p>
                                          </td>
                                          <td style="min-width:80px" class=""> 
                                              <div class="hover-btn ">
                                                  <!-- <button type="button" class="btn btn-secondary  text-gray-600 btn-sm rounded-4 py-1 mr-3 ">
                                                      <small>Details</small>
                                                  </button> -->
                                              </div>
                                             
                                          </td>
                                      </tr>
                                  
                                  </tbody>
                              </table>
                              
                              <p v-if="noRecordsYet" class="text-center mt-6 text-13">No Records</p>
  
                              <div  class="justify-content-end mt-5 d-flex">
                                  <div>
                                      <button :disabled="currentPage==1" @click="getPreviousPage" class="btn btn-outline-secondary btn-sm">Previous</button>
                                      <button :disabled="currentPage==totalPages" @click="getNextPage" class="btn btn-outline-secondary btn-sm ml-3">Next</button>
                                  </div>
                              </div>
                             
                              <DataLoader ref="dataLoader"/>
                          
                          </div>
                      </div>
                  </div>
  
              </div>
              
  
              
          
              
          </div>
          
        </div>
    </div>
  
  </template>
  
  
  <script>
  // @ is an alias to /src
  import SideNav from '../SideNav.vue'
  import TopBar from '../Elements/TopBar.vue'
  import commons from "../../commons"
  import axios from "axios";
  // import Button from "../Elements/Button.vue"
  import iziToast from "../../mixin/IziToast"
  import DataLoader from "../Elements/DataLoader.vue"
  
  export default {
    name: 'WebHooks',
    mixins:[commons,axios,iziToast],
    components: {
      SideNav,TopBar,DataLoader
    },
    data() {
        return {
          collection_url:"",
          payment_url:"",
          noLinksYet:true,
          createNewLink:false,
          nextCursor:null,
          prevCursor:null,
          collections:[],
          noRecordsYet:false,
          totalPages:0,
          lastCursor:null,
          currentPage:1,
          filterData:null
      
        }
    },
    mounted() {
  
      this.getCollections()
    },
  
    methods: {
      downloadCollections(){
          this.showSuccessMessage("Please wait","topRight")
  
        axios.get(`${this.baseUrl}/api/admin/collections/download`,{
              headers: {
                  Authorization: 'Bearer ' + this.getCookie("accessToken"),
              },
              responseType: 'blob'
          }).then((res)=>{
              const url = URL.createObjectURL(new Blob([res.data], {
                  type: 'application/vnd.ms-excel'
              }))
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', "Collections Recieved.xlsx")
              document.body.appendChild(link)
              link.click()
          }).catch((error)=>{
              console.log(error);
          })
      },
      filterChanged(data){
          this.filterData = data
      },
      accountSwitched(){
          this.getCollections();
      },
      enviromentChanged(){
          this.getCollections()
      },
      getkey(){
        axios.get(`${this.baseUrl}/api/admin/keys`,{
          headers: {
                Authorization: 'Bearer ' + this.getCookie("accessToken"),
            }
        }).then(()=>{
          // if (res.data) {
          //     if (res.data.data.active_environment =="test") {
          //         this.getCollections(res.data.data.test_key);
          //     }else{
          //         this.getCollections(res.data.data.production_key);
          //     }
          // }
         
        })
      },
      getNextPage(){
          this.getCollections()
           this.currentPage+=1;
      },
      getPreviousPage(){
          this.nextCursor = this.prevCursor
          this.getCollections()
          this.currentPage-=1;
      },
      getCollections(){
          this.noRecordsYet = false
          this.collections =[]
          this.$nextTick(() => {
              this.$refs.dataLoader.start()
          });
  
          var collectionsUrl =""
          if (this.nextCursor) {
              collectionsUrl = `${this.baseUrl}/api/admin/collections?cursor=${this.nextCursor}`;
          }else{
              collectionsUrl = `${this.baseUrl}/api/admin/collections`;
          }
           axios.get(collectionsUrl,{
                  headers: {
                      Authorization: 'Bearer ' + this.getCookie("accessToken"),
                  }
              }).then((res)=>{
                  this.collections =[];
                  this.collections.length =0;
                  this.collections = res.data.data.items;
                  this.nextCursor =res.data.data.nextCursor
                  this.$refs.dataLoader.stop()
                  if (res.data.data.items.length ==0) {
                      this.noRecordsYet = true
                  }
                  this.totalPages = res.data.data.totalPages
                  this.lastCursor = res.data.data.lastCursor
              }).catch((error)=>{
                  console.log(error);
              })
      }
        
    },
  }
  </script>
  