import axios from "axios";
import numeral from "numeral";
import moment from "moment";

var ProgressBar = require('progressbar.js')


const commons = {

    data() {
        return {
            baseUrl:process.env.VUE_APP_API_URL,
            appUrl:process.env.VUE_APP_URL,
            landingUrl:process.env.VUE_APP_LANDING_URL,
            parentDomain:process.env.VUE_APP_PARENT_DOMAIN,
            showPage:false,
            openPage:"",
            line:null,
            showLine:false,
            lineWidth:.15,
            userProfile:null,
            circlePageLoader:false,
            isChoosingAccount:false,
            storageUrl:"https://storage.googleapis.com"
        }
    },

    mounted() {
        if (screen.availWidth<=768) {
            this.lineWidth =.5
        }
        this.$nextTick(() => {
            if (this.openPage =="auth_page") {
                document.body.classList.add('bg-white');
            }else{
                document.body.classList.remove('bg-white');
            }
        });

        this.createProgress()
    },

    computed: {
        formatDate2(){
            return (value)=>{
                return moment(value.toString()).format('MMM DD, YYYY HH:mm');
              }
        },
        formatDate3(){
            return (value)=>{
                return moment(value.toString()).format('MMM DD, YYYY');
              }
        },
        formatDate4(){
            return (value)=>{
                return moment(value).format('DD MMM, YYYY');
              }
        },
        formatDate(){
            return (value)=>{
                return moment(value.toString()).format('DD-MMM-YYYY');
              }
        },
        HourMinutes(){
            return (value) => {
                return moment(value.toString()).format('HH:mm');
              }
        },
        formatNumber(){
            return (value)=>{
                return numeral(value).format("0,0");
              }
        }
        

    },

    methods: {
        createProgress(){
            if (document.getElementById("lineprogress")) {
               
                this.$nextTick(() => {
                    this.line = new ProgressBar.Line('#lineprogress', {
                        strokeWidth: this.lineWidth,
                        color: '#4EB969',
                        trailWidth: 1,
                        duration: 10000,
                        
                    });
                //    this.startProgress()
                });
            }
        },
        startProgress(){
            // console.log("xxxxxxxxxxxxxxxxx")
            // this.line.set(0)
            this.showLine = true;
            this.line.animate(1,()=> {
                setTimeout( ()=>{
                    this.showLine = false;                                      
                  }, 500);
               
            });
        },

        finishProgress(){
            if (this.line) {
                this.line.set(1)
                // this.line.destroy()
            }

        },
        validatePassword(newPassword) {
            var minNumberofChars = 8;
            // var regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;

            if(newPassword.length < minNumberofChars){
                return false;
            }
            
            if (!newPassword.match(/[a-z]/g) ||  !newPassword.match(/[A-Z]/g) ||  !newPassword.match(/[0-9]/g) 
            || !newPassword.match(/[!@#$%^&*]/g) ) {
                return false;
            }
            
            return true;
        },
        validateEmail(email) {
            return String(email)
              .toLowerCase()
              .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              );
        },
        checkAuthorization(){
            if (this.getCookie("accessToken") == undefined) {
                if (this.openPage !="auth_page") {
                    this.$router.push("/login")
                }else{
                    this.showPage = true;
                }
            }else{
                this.isLoding = true;
                this.startProgress();
                axios.get(`${this.baseUrl}/api/admin/profile`,{
                    headers: {
                        Authorization: 'Bearer ' + this.getCookie("accessToken"),
                    }
                }).then((res)=>{
                    this.userProfile = res.data.data
                    this.showPage = true;
                    this.finishProgress()
                }).catch(()=>{
                    // localStorage.removeItem("accessToken")
                    if (this.openPage !="auth_page") {
                        this.$router.push("/login")
                    }else{
                        this.showPage = true;
                    }
                    this.finishProgress()
                })
            }

        },
        getCookie(cname) {
            let name = cname + "=";
            let decodedCookie = decodeURIComponent(document.cookie);
            let ca = decodedCookie.split(';');
            for(let i = 0; i <ca.length; i++) {
              let c = ca[i];
              while (c.charAt(0) == ' ') {
                c = c.substring(1);
              }
              if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
              }
            }
            return "";
        },
        saveCookie(key,value){
            const today = new Date()
            const tomorrow = new Date(today)
            tomorrow.setDate(tomorrow.getDate() + 1)
            if(process.process.env.VUE_APP_PARENT_DOMAIN ==".ntuma.app"){
            document.cookie = key+"="+value+"; expires="+tomorrow+"; domain="+process.env.VUE_APP_DOMAIN+"; Secure;";
            }else{
            document.cookie = key+"="+value+"; expires="+tomorrow+"; domain="+process.env.VUE_APP_DOMAIN+";";
            }
        },

        deleteCookie(key){
            const value ="";
            if(process.env.VUE_APP_PARENT_DOMAIN ==".ntuma.app"){
            document.cookie = key+"="+value+"; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain="+process.env.VUE_APP_DOMAIN+"; Secure;";
            }else{
            document.cookie = key+"="+value+"; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain="+process.env.VUE_APP_DOMAIN+";";
            }
        },

        
    }
};

export default commons;
