
<template>
  <div>
     <div class="fixed-top">
        <div style="z-index: 1007;" class="line-container w-full ">
          
          <div v-show="showLine" id="lineprogress"></div></div>
        <div  class="top-header custom-nav ">
          <div class=" plogo ">
              <div  class="header-logo">
                  <img width="80" src="../../assets/ntuma_logo_3.svg" alt="logo" >
              </div>
          </div>
          <div class="w-full container-fluid px-middle">
            
            <div class="d-flex ">
                <div style="align-items: flex-end" class="w-2-of-3 d-flex ">
                   
                    <span  class="text-header" >
                   
                      {{userProfile.firstname}} {{userProfile.lastname}}
                    </span>
                      
                </div>
                <div class="w-1-of-3 justify-content-end d-flex">
              
                    <div class="d-flex">
                        <SearchComponent/>
                        <div @click="showRighNav" class="profile-div ml-3 flex-bottom">
                            <img  width="30" src="../../assets/profile.svg" alt="logo" >
                        </div>
                        <!-- <div class="flex-center notification-div px-3 ml-3">
                            <span >6 </span>
                        </div> -->
                    </div>
                </div>
            </div>
          </div>
  
        </div>
        <div class="header-divider"></div>
      </div>
  
      <div id="righNav" class="bg-primary fixed position-absolute; z-index:1000 fixed-top ml-auto right-sidenav ">
          <div class="h-full d-flex flex-column">
              <div style="    border-bottom: 1px solid rgb(173 180 189);" class="d-flex px-4 pt-5">
                <div class="w-full">
                  <p class="text-white"> <b>Profile</b> <br>
                  <small class="text-white-muted text-12"> <span class="text-yellow"> <i class="fa fa-circle text-10"></i> {{profileProgress}}% </span> complete</small>
                  </p>
                </div>
                <div class="">
                  <span class="text-white" style="font-size:30px;cursor:pointer;opacity:.5" @click="closeNav()">&times; </span>
                </div>
              </div>
              <div  class=" py-4 py-3 flex-grow-1 px-4">
                <ul>
                  <li>
                    <router-link to="/account-details">
                        <span class="">Profile</span>
                    </router-link>
                  </li>
                  <li> <a href="">Privacy</a> </li>
                  <li> <a target="_blank" href="https://ntuma.app/contact">Help & Support</a> </li>
                </ul>
              </div>
              <div class="py-3 px-4 ">
                 <ul>
                    
                    <li><a @click.prevent="logOut" href=""> <img class="mr-2" width="20" src="../../assets/logout_icon.svg" alt="" srcset=""> 
                    <span>logout</span>
                    </a></li>
                   
                </ul>
                  
              </div>
          </div>
      </div>

      
    <div data-bs-backdrop="static" data-bs-keyboard="false" class="modal" id="modalLoader">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          
          <div class="modal-body text-center py-5">
            <p> Please wait....</p>
            <div class="spinner-border"></div>
          </div>
          
        </div>
      </div>
    </div>
  
  
  
  </div>
     
  </template>
  <script>
  import SearchComponent from './SearchComponent.vue'
  import commons from "../../commons"
  import axios from "axios";
  import Swal from "sweetalert2"
  import iziToast from "../../mixin/IziToast"
  
  
  // @ is an alias to /src
  export default {
    name: 'PinInput',
    mixins:[commons,iziToast],
    components: {
      SearchComponent
    },
   
    data() {
      return {
        pinValue:"",
        enviromentMode:"test",
        userProfile:{},
        ntumaAccount:{},
        accountName:"",
        currentAccountId:null,
        profileProgress:60
      }
    },
    props:[],
    mounted() {
      this.$nextTick(() => {
          this.checkAuthorization();
      });
  
      this.loadInit()
    
      
  
    },
    methods: {
      loadInit(){
        this.getUserProfile()
        // this.getEnviroment()
        // this.getNtumaAccount()
      },
      getUserProfile(){
        axios.get(`${this.baseUrl}/api/admin/profile`,{
            headers: {
                Authorization: 'Bearer ' + this.getCookie("accessToken"),
            }
        }).then((res)=>{
            this.userProfile = res.data.data
            console.log( this.userProfile)
            this.$emit("user-profile",  this.userProfile)
        }).catch(()=>{
            
        })
      },
      saveAccount(res){
         axios({
          method: 'post',
          url: `${this.baseUrl}/api/admin/ntuma-account/create-account`,
          headers: {
            Authorization: 'Bearer ' + this.getCookie("accessToken"),
          },
          data:{
            account_name:this.accountName
          }
        }).then(()=>{
          this.$refs.saveNewAccountBtn.finishProcessing();
          this.showSuccessMessage("You have switched to another account")
          this.loadInit()
          // eslint-disable-next-line
          $("#newNtumaAccount").modal("hide")
          this.$emit("account-switched",  res.data.data)
        }) .catch( (error)=> {
          this.$refs.saveNewAccountBtn.finishProcessing();
          if (error.response) {
            this.showErrorMessage(error.response.data.message)
          }else{
            this.showErrorMessage("Something went wrong")
          }
        });
      },
      
      showAccountList(){
        // eslint-disable-next-line
        $("#listAccount").modal("show")
  
      },
      switchToAccount(ntuma_account_id){
        
          // eslint-disable-next-line
          $("#listAccount").modal("hide")
           // eslint-disable-next-line
          $("#modalLoader").modal("show")
          this.startProgress();
            axios({
                method: 'post',
                url: `${this.baseUrl}/api/admin/ntuma-account/switch-account`,
                headers: {
                  Authorization: 'Bearer ' + this.getCookie("accessToken"),
                },
                data:{
                      ntuma_account_id:ntuma_account_id
                }
            }).then((res)=>{
                this.showSuccessMessage("You have switched to another account","topLeft")
                this.loadInit()
                this.finishProgress()
                   // eslint-disable-next-line
                 $("#modalLoader").modal("hide")
                   this.$emit("account-switched",  res.data.data)
            }) .catch( (error)=> {
                    // eslint-disable-next-line
                $("#modalLoader").modal("hide")
                if (error.response) {
                  this.showErrorMessage(error.response.data.message)
                }else{
                  this.showErrorMessage("Something went wrong")
                }
            });
  
      },
      getNtumaAccount(){
        axios.get(`${this.baseUrl}/api/admin/ntuma-account`,{
            headers: {
                Authorization: 'Bearer ' + this.getCookie("accessToken"),
            }
        }).then((res)=>{
            this.ntumaAccount = res.data.data
            if (!this.ntumaAccount ) {
              // eslint-disable-next-line
              $("#listAccount").modal("show")
            }else{
              this.currentAccountId = this.ntumaAccount.id;
            }
            this.$emit("ntuma-account",  this.ntumaAccount)
            if (this.ntumaAccount.business_details) {
              this.profileProgress =90
            }
        }).catch(()=>{
            
        })
      },
      getEnviroment(){
         axios.get(`${this.baseUrl}/api/admin/keys`,{
          headers: {
                Authorization: 'Bearer ' + this.getCookie("accessToken"),
            }
        }).then((res)=>{
          if (res.data) {
            this.enviromentMode = res.data.data.active_environment
            this.$emit("emit-enviroment",  this.enviromentMode)
          }
        })
      },
      changeMode(mode){
          Swal.fire({
            title: 'Confirm?',
            text: "Change mode to "+mode,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#4EB969',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Change mode'
          }).then((result) => {
            if (result.isConfirmed) {
                  axios({
                      method: 'post',
                      url: `${this.baseUrl}/api/admin/keys/set-environment`,
                      headers: {
                        Authorization: 'Bearer ' + this.getCookie("accessToken"),
                      },
                      data:{
                          environment:mode
                      }
                  }).then(()=>{
                      this.showSuccessMessage("You have changed to "+mode+" mode")
                      this.$emit("enviroment-changed")
  
                      this.loadInit()
                  }) .catch( (error)=> {
                      if (error.response) {
                        this.showErrorMessage(error.response.data.message)
                      }else{
                        this.showErrorMessage("Something went wrong")
                      }
                  });
            }
          })
      },
  
      showRighNav(){
        // document.getElementById("righNav").style.display ="block"
        this.openNav()
      },
  
       openNav() {
        document.getElementById("righNav").style.width = "350px";
      },
  
      closeNav() {
        document.getElementById("righNav").style.width = "0";
      },
  
       logOut(){
        localStorage.removeItem("token")
        this.$router.push("/");
      }
    
    },
  
  
  }
  </script>
  