<template>
    <div class="wrapper-div ">
        <TopBar  />

    
        <div  class="d-flex  ">
          <SideNav ActiveTab ="settlements"/>
          <div  class="container-fluid px-middle top-div">
             
              <div  class="card data-card shadow-sm ">
                  <div class="card-body def-card-h pt-5 px-5">
                      <div class="">
                          <div class=" ">
                              <div class="d-flex px-table-top mb-2">
                                <div class="w-5-of-6 d-flex">
                                    <p  > <b>Settlements</b> </p>
                                </div>
                                <div class="d-flex justify-content-end w-1-of-6  pb-3">
                                </div>
                              </div>
                              <table style="box-shadow: none;" class="table table-borderless  table-hover">
                                  <thead class="">
                                          
                                        <tr>
                                            
                                            <th>Ntuma Account</th>
                                            <th>Amount</th>
                                            <th>Status</th>
                                            <th>Created</th>
                                            <th>Date</th>
                                            <th></th>
                                        </tr>
                                      
                                  </thead>
                                  <tbody>
                                    <tr :key="key" v-for="(settlement,key) in settlements">
                                        <td>
                                            <p> {{ settlement.ntuma_account.account_name }} - {{ settlement.ntuma_account.id }} </p>
                                        </td>
                                        <td>
                                            <p class="mt-link">{{ formatNumber(settlement.amount) }}</p>
                                        </td>
                                        <td>
                                            <p class="mt-link ">
                                             <span class="badge bg-gray-200 text-13 text-gray-800 text-normal" v-if="settlement.status=='pending'">Pending</span>
                                                <span class="badge bagde-3-danger text-13 text-gray-800" v-if="settlement.status=='rejected'">Rejected</span>
                                            <span class="badge bagde-3-success text-13 text-gray-800" v-if="settlement.status=='successful'">Successful</span>
                                            </p>
                                        </td>
                                        <td>
                                            <p class="mt-link">{{ settlement.creator.firstname }} {{ settlement.creator.lastname }}</p>
                                        </td>
                                        <td>
                                            <p class="mt-link">{{ formatDate2(settlement.created_at)}}</p>
                                        </td>
                                        <td>
                                            <div class="hover-btn d-flex justify-content-end mr-5">
                                                <button @click="changeStatus(settlement)" class="btn btn-secondary  text-gray-600 btn-sm rounded-4 py-1 ">Change Status</button>
                                                <!-- <div class="dropdown">
                                                    <button type="button" class="btn btn-secondary  text-gray-600 btn-sm rounded-4 py-1 " data-bs-toggle="dropdown">
                                                        action
                                                    </button>
                                                    <ul class="dropdown-menu">
                                                        <li><a class="dropdown-item" href="#">Approve</a></li>
                                                        <li><a class="dropdown-item" href="#">Reject</a></li>
                                                    </ul>
                                                </div> -->
                                                
                                              </div>
                                        </td>
                                        
                                    </tr>
                                  </tbody>
                              </table>
                              
                              <p v-if="noRecordsYet" class="text-center mt-6 text-13">No Records</p>
                              <DataLoader ref="dataLoader"/>
                              <div  class="justify-content-end mt-5 d-flex">
                                  <div>
                                      <button :disabled="currentPage==1" @click="getPreviousPage" class="btn btn-outline-secondary btn-sm">Previous</button>
                                      <button :disabled="currentPage==totalPages" @click="getNextPage" class="btn btn-outline-secondary btn-sm ml-3">Next</button>
                                  </div>
                              </div>
                             
                             
                          
                          </div>
                      </div>
                  </div>
  
              </div>
                <div class="modal" id="changeStatusModal">
                    <div class="modal-dialog modal-md">
                        <div class="modal-content">

                        <!-- Modal Header -->
                        <div class="modal-header">
                            <h5 v-if="selectedSettlement.ntuma_account" class="modal-title">Settlement Request ({{ selectedSettlement.ntuma_account.account_name }} - {{ selectedSettlement.ntuma_account.id }})</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                        </div>

                        <!-- Modal body -->
                        <div class="modal-body">
                            <div class="form-group">
                                <label for="">Amount</label>
                                <input class="form-control" disabled type="text" :value="  formatNumber(selectedSettlement.amount)  ">
                            </div>

                            <div class="form-group mt-4  ">
                                <label for="">Status</label>
                                <select v-model="selectedSettlement.status" class=" form-select" name="" id="">
                                    <option value="pending">Pending</option>
                                    <option value="successful">Successful</option>
                                    <option value="rejected">Rejected</option>
                                </select>
                            </div>
                            <div class="form-group mt-4 mb-4">
                                <label for="">Comment</label>
                                <textarea class="form-control" v-model="comment" name="" id="" cols="30" rows="5"></textarea>
                            </div>
                        </div>
                       

                        <!-- Modal footer -->
                        <div class="modal-footer">
                            <Button @click.prevent="updateSettlement" ref="updateSettlementBtn"  Text="Save changes"
                                            Class="btn project-btn-yellow"/>
                            <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                        </div>

                        </div>
                    </div>
                </div>
              
                <div class="modal" id="requestWithdraw">
                    <div class="modal-dialog modal-md">
                        <div class="modal-content px-5 pb-5 pt-3">
                            <div class="modal-body">
                                <div class="form-group mt-4">
                                    <label for="">Wallet</label>
                                    <select name="" class="form-select" id="">
                                        <option value="">UGX {{ formatNumber(walletBalance) }}</option>
                                    </select>
                                </div>
                          
                                <div class="form-group mt-4">
                                    <label for="">Amount(UGX)</label>
                                    <MoneyComponent ref="amountInputComponent" inputClass="form-control py-3" @amount-entered="amountEntered" inputName="amount"/>
                                    <p class="text-danger" v-if="amount >walletBalance"> Invalid Amount </p>
                                    <!-- <input class="form-control py-3" placeholder="" name="email" type="email"> -->
                                </div>

                                <!-- <div class="form-group mt-3 mb-3">
                                    <Button 
                                        @click.prevent="createSettlement"
                                        ref="createSettlementBtn"  
                                        Text="SUBMIT"
                                        Class="btn px-2  mt-3 py-3 project-btn-green w-full"
                                    />
                                </div> -->
                            </div>

                        </div>
                    </div>
                </div>
          </div>
          
        </div>
    </div>
  
  </template>
  
  
  <script>
  // @ is an alias to /src
  import SideNav from '../SideNav.vue'
  import TopBar from '../Elements/TopBar.vue'
  import commons from "../../commons"
  import axios from "axios";
  import Button from "../Elements/Button.vue"
  import iziToast from "../../mixin/IziToast"
  import DataLoader from "../Elements/DataLoader.vue"
  import MoneyComponent from "../Elements/MoneyComponent.vue"

  export default {
    name: 'SettlementComponent',
    mixins:[commons,axios,iziToast],
    components: {
      SideNav,TopBar,DataLoader,Button,MoneyComponent
    },
    data() {
        return {
          noLinksYet:true,
          createNewLink:false,
          nextCursor:null,
          prevCursor:null,
          settlements:[],
          noRecordsYet:false,
          totalPages:0,
          lastCursor:null,
          currentPage:1,
          bankDetails:{},
          amount:0,
          walletBalance:0,
          ntumaAccount:{},
          enviromentMode:"test",
          selectedSettlement:{},
          comment:""
        }
    },
    mounted() {
  
      this.getSettlements()
    },
  
    methods: {
        changeStatus(selectedSettlement){
            this.selectedSettlement =selectedSettlement
            // eslint-disable-next-line
            $("#changeStatusModal").modal("show")
        },
        updateSettlement(){
            axios({
                method: 'post',
                url: `${this.baseUrl}/api/admin/settlements/change-status`,
                headers: {
                Authorization: 'Bearer ' + this.getCookie("accessToken"),
                },
                data:{
                    settlement_id:this.selectedSettlement.id,
                    status: this.selectedSettlement.status,
                    comment:this.comment
                }
            }).then(()=>{
                this.$refs.updateSettlementBtn.finishProcessing()

                this.showSuccessMessage("success")
                // eslint-disable-next-line
                $("#changeStatusModal").modal("hide")
                this.resetPagination()

            }) .catch( (error)=> {
                this.$refs.updateSettlementBtn.finishProcessing()
                if (error.response) {
                this.showErrorMessage(error.response.data.message)
                }else{
                this.showErrorMessage("Something went wrong")
                }
            });
        },
       
        resetPagination(){
            this.nextCursor =null;
            this.totalPages=0
            this.lastCursor=null
            this.currentPage=1
            this.getSettlements()
        },
        getNextPage(){
            this.getSettlements()
            this.currentPage+=1;
        },
        getPreviousPage(){
            this.nextCursor = this.prevCursor
            this.getSettlements()
            this.currentPage-=1;
        },
        getSettlements(){
            this.noRecordsYet = false
            this.settlements =[]
            this.$nextTick(() => {
                this.$refs.dataLoader.start()
            });
            var settlementsUrl =""
            if (this.nextCursor) {
                settlementsUrl = `${this.baseUrl}/api/admin/settlements?cursor=${this.nextCursor}`;
            }else{
                settlementsUrl = `${this.baseUrl}/api/admin/settlements`;
            }
            axios.get(settlementsUrl,{
                    headers: {
                        Authorization: 'Bearer ' + this.getCookie("accessToken"),
                    }
                }).then((res)=>{
                    this.settlements =[];
                    this.settlements.length =0;
                    this.settlements = res.data.data.items;
                    this.nextCursor =res.data.data.nextCursor
                    this.$refs.dataLoader.stop()
                    if (res.data.data.items.length ==0) {
                        this.noRecordsYet = true
                    }
                    this.totalPages = res.data.data.totalPages
                    this.lastCursor = res.data.data.lastCursor
                }).catch((error)=>{
                    console.log(error);
                })
        }
        
    },
  }
  </script>
  