<template>
    <div class="bg-white">
        <TopBar />
        <div  class="d-flex  ">
            <SideNav ActiveTab ="verify-transaction"/>
            <div  class="container-fluid px-middle top-div">
                <div  class="card data-card shadow-sm ">
                    <div class="card-body def-card-h pt-5 px-5">
                        <div class="d-flex px-table-top mb-2">
                            <div class="w-5-of-6">
                                <p><b>Verify Transaction</b></p>
                                <div Class="d-flex h-10">
                                    <input v-model="transactionId" type="text" class="form-control" name="transaction_id">
                                    <Button ref="searchTx" @button-clicked="searchTransaction()" Text="Search" Class="btn px-3 ml-2 project-btn-yellow w-32 h-full" />
                                </div>
                                <div v-if="transaction" Class="mt-5">
                                    <table class="table table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Transaction Id</th>
                                                <th scope="col">Ntuma Status</th>
                                                <th scope="col">{{ transaction.yoStatus ? 'Yo Status' : 'Flutterwave Status'}}</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style="padding: 20px 12px;">{{ transaction.transaction_id }}</td>
                                                <td style="padding: 20px 12px;">{{ transaction.status }}</td>
                                                <td style="padding: 20px 12px;">{{ transaction.yoStatus || transaction.flutterwaveStatus }}</td>
                                                <td style="padding: 12px;">
                                                    <Button
                                                        ref="verifyTx"
                                                        @button-clicked="verifyTransaction()"
                                                        Text="Verify"
                                                        Class="btn px-2 project-btn-yellow w-32"
                                                        :disabled="!buttonDisabled"
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div> 
                    </div></div>
                </div>
        </div>
        <DataLoader ref="dataLoader"/>
    </div>
</template>

<script>
import axios from 'axios';
import DataLoader from '../Elements/DataLoader.vue';
import TopBar from '../Elements/TopBar.vue';
import SideNav from '../SideNav.vue';
import Button from '../Elements/Button.vue';
import commons from '@/commons';

export default {
    name: 'VerifyTransaction',
    components: {
        SideNav, TopBar, DataLoader, Button
    },
    mixins:[commons, axios],
    data() {
        return {
            transaction: null,
            buttonDisabled: true
        }
    },
    methods: {
        async searchTransaction() {
            try {
                const url = `${this.baseUrl}/api/admin/get-transaction`
                const response = await axios({
                    method: 'POST',
                    url,
                    headers: {
                        Authorization: `Bearer ${this.getCookie("accessToken")}`,
                    },
                    data: {
                        transactionId: this.transactionId
                    }
                })
                this.transaction = response.data.data
                this.buttonDisabled = this.transaction.yoStatus ? 
                    (this.transaction.status !== this.transaction.yoStatus) : 
                    (this.transaction.status !== this.transaction.flutterwaveStatus)
                    console.log('Trna => ', this.transaction)
            } catch (err) {
                console.log("Err => ", err)
            } finally {
                this.$refs.searchTx.finishProcessing()
            }
        },
        async verifyTransaction() {
            try {
                const url = `${this.baseUrl}/api/admin/recheck-transaction`
                const response = await axios({
                    method: 'POST',
                    url,
                    headers: {
                        Authorization: `Bearer ${this.getCookie("accessToken")}`,
                    },
                    data: {
                        transactionId: this.transaction.transaction_id
                    }
                })
                this.transaction = response.data.data
            } catch (err) {
                console.log("Error: ", err)
            } finally {
                this.$refs.verifyTx.finishProcessing()
            }
        }
    }
}

</script>