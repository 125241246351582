<template>
 <div class="bg-white full-hieght">
  <div class="container">
    <div class="row justify-content-center pt-6 pt-auth-container mt-3 pb-4">
        <div class="col-md-12 col-lg-9 col-xl-8 col-sm-12 col-xs-12">
          <div class="card sharp-card">
              <div class="card-body py-0 pr-0 px-mobile">
                  <div class="row">
                      <div class="col-md-6 pt-7 pt-mobile pb-mobile">
                          <div class="d-flex justify-content-center">
                            <div class=" w-4-of-5 pt-3 px-3">
                              <!-- <router-link class="" to="/">
                              <img width="30"  src="../assets/back_button.svg" alt="logo" >
                             </router-link> -->
                              <p class="login-header text-lg"> <b>Sign in to admin account</b></p>
                              <div class="pr-5">
                                <img  class="w-full mt-2"  src="../assets/login_chair_icon.svg" alt="logo" >
                              </div>
                            </div>
                          </div>
                      </div>
                      <div class="col-md-6 bg-login pt-5 pb-5">
                        <div class="text-center  mt-3">
                          <img width="80" src="../assets/ntuma_logo.svg" alt="logo" >
                        </div>
                        <div class="container px-tagline">
                          <div class="d-flex mt-4 tagline">
                              <div class="w-1-of-3">
                                <hr>
                              </div>
                              <div class="w-1-of-3 text-center">
                                <small class="text-white-muted " style="font-size:10px">Sign in</small>
                              </div>
                              <div class="w-1-of-3">
                                <hr>
                              </div>
                          </div>
                        </div>

                        <div class="container auth-form pt-2">
                          <!-- <div class="input-group ">
                            <span class="input-group-text px-3 auth-input">@</span>
                            <input type="text" class="form-control form-control auth-input ml-0" placeholder="Username">
                          </div> -->
                           <div v-if="showResetSucess" class="d-flex w-full bg-primary slide mb-3">
                              <div class="bg-green px-3 py-2">
                                <img width="15" src="../assets/tick.svg" alt="logo" >
                              </div>
                              <div class="bg-white text-center w-full pt-2">
                                <span class="text-green singup-label">Your password has been reset</span>
                              </div>
                              
                          </div>

                          <div class="form-group">
                            
                            <label class="text-white-muted singup-label pl-label" for=""><small>Email</small> </label>
                            <div class="input-group ">
                              <span class="input-group-text px-3 auth-input"> <span class="fa fa-envelope"></span> </span>
                              <input v-model="emailOrPhone" type="email" class="form-control  ml-0 auth-input" name="email_or_phone">
                            </div>

                          </div>
                          <div class="form-group mt-form">
                            <div  class="d-flex w-full">
                              
                              <label class="text-white-muted singup-label pl-label" for=""><small>Password</small> </label>
                              <div  class=" w-full text-end">
                              
                             
                               
                              </div>
                            </div>
                            <PasswordInput v-slot="slotProps" ref="passwordRef">
                                 <div class="input-group ">
                                    <span class="input-group-text px-3 auth-input"> <span class="fa fa-lock"></span> </span>
                                    <input v-model="password" :type="(slotProps.isVisible)?'text':'password'" class="form-control ml-0 mr-0 auth-input" name="email_or_phone">
                                    <span class="input-group-text px-3 auth-input  ml-0">  <span @click="this.$refs.passwordRef.changePasswordVisibility" :class="(slotProps.isVisible)?'fa-eye opacity-full':'fa-eye-slash'" class="fa eye-icon"></span> </span>
                                  </div>
                            </PasswordInput>
                           
                          </div>
                          <div class="d-flex mt-form">
                              <div class="form-check ">
                                <input class="form-check-input auth-input p-checkbox" type="checkbox" value="" id="flexCheckChecked" >
                                <label class="form-check-label" for="flexCheckChecked mt--2"> </label>
                              </div>
                              <p style="font-size:11px" class=" text-white-muted mt-2"> Remember me</p>
                          </div>
                             <Button ref="loginBtn" @button-clicked="LoginToAccount()" Text="Login"
                              Class="btn px-2  mt-3 mb-5 project-btn-yellow w-full"/>
                        </div>
                       

                       
                      
                      </div>
                  </div>
              </div>
          </div>
        </div>
    </div>
  </div>
 </div>

</template>

<script>
// @ is an alias to /src
import commons from "../commons"
import axios from "axios";
import Button from "./Elements/Button.vue"
import PasswordInput from "./Elements/PasswordInput.vue"
import iziToast from "../IziToast"
import moment from "moment";


export default {
  name: 'LoginPage',
  mixins:[commons,axios,iziToast],
  components: {
    Button,PasswordInput
  },
  data() {
      return {
        firstName:null,
        lastName:null,
        emailOrPhone:null,
        password:null,
        showResetSucess:false
      }
  },
  mounted() {
    this.openPage ="auth_page";
    this.$nextTick(() => {
          let passwordreset = this.$route.params.passwordreset;
          if(passwordreset){
            this.showResetSucess = true
          }
    });
  },

  methods: {
      LoginToAccount(){
        if(this.emailOrPhone && this.password){
            var emailOrPhone =this.emailOrPhone;

            if (this.isPhoneNumber(this.emailOrPhone)) {
              emailOrPhone = this.emailOrPhone.replace(" ","").replace("+","").replace("-","");
              emailOrPhone = emailOrPhone.substr(emailOrPhone.length - 9);
            }

            if (!this.validateEmail(this.emailOrPhone)) {
                this.showErrorMessage("Email is Invalid")
                this.$refs.loginBtn.finishProcessing();
                return
            }

            const url = `${this.baseUrl}/api/admin/login`

            axios({
                    method: 'post',
                    url,
                    headers: {}, 
                    data: {
                        emailOrPhone: emailOrPhone,
                        password: this.password
                    }
                }).then((res) => {
                    this.$refs.loginBtn.finishProcessing();
                      if (res.data.status =="success") {
                      let data ={email:res.data.data.user.email,otp:res.data.data.otp} ;

                      this.$router.push({
                          name: "ConfirmIdentity", //use name for router push
                          params: data,
                          query:data
                      });
                      localStorage.setItem("lastOtpTime", moment());

                    } else {
                      this.showErrorMessage("Credentials do not match our records")
                    }
                    
                }).catch( ()=> {
                    this.$refs.loginBtn.finishProcessing();
                    this.showErrorMessage("Credentials do not match our records")
                });
        } else {
          this.showErrorMessage("All fields are required")
          this.$refs.loginBtn.finishProcessing();
        }
      },
      isPhoneNumber(inputtxt) {
          var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
          if (inputtxt.match(phoneno)) {
            return true;
          } else {
            return false;
          }
      }
  },
}
</script>
