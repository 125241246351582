<template>
    <div>
      <button :disabled="(isLoading) || disabled" @click.prevent="buttonClicked()" :class="Class"> 
        <span v-if="isLoading">
            <div class="spinner-border spinner-border-sm"></div>
            Please wait
        </span>
        <span v-else>{{Text}}</span>
 </button>
    </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'VueButton',
  components: {},
  data() {
    return {
      isLoading:false
    }
  },
  props: {
    Class: {
      type: String,
      default: ''
    },
    Text: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
      buttonClicked(){
          this.isLoading = true;
          this.$emit('buttonClicked');
      },
      finishProcessing(){
        this.isLoading = false;
      }
  },


}
</script>
